var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    attrs: {
      "header-tag": "header",
      "role": "tab"
    },
    on: {
      "click": function ($event) {
        _vm.visible = !_vm.visible;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_c('b', [_vm._v(_vm._s(`Level ${_vm.level} `))]), _vm._v(_vm._s(_vm.title))]), _vm.visible ? _c('font-awesome-icon', {
    attrs: {
      "icon": "angle-down"
    }
  }) : _c('font-awesome-icon', {
    attrs: {
      "icon": "angle-right"
    }
  })], 1)]), _c('b-collapse', {
    attrs: {
      "role": "tabpanel"
    },
    model: {
      value: _vm.visible,
      callback: function ($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c('b-card-body', {
    staticClass: "blockquote"
  }, [_vm.comment.customize ? [_vm._v(_vm._s(_vm.comment.customize))] : [_vm._v(_vm._s(_vm.comment.en) + " " + _vm._s(_vm.comment.tw))]], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }