<template lang="pug">
b-card(no-body)
  b-card-header(header-tag="header" role="tab" @click="visible = !visible")
    div.d-flex.justify-content-between.align-items-center
      span
        b {{ `Level ${level} ` }}
        | {{ title }}
      font-awesome-icon(v-if="visible" icon="angle-down")
      font-awesome-icon(v-else icon="angle-right")
  b-collapse(v-model="visible" role="tabpanel")
    b-card-body.blockquote
      template(v-if="comment.customize")
        | {{ comment.customize }}
      template(v-else)
        | {{ comment.en }}
        | {{ comment.tw }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    level: {
      type: Number,
      default: 0,
    },
    comment: {
      type: Object,
      default: () => ({
        customize: '',
        tw: '',
        en: '',
      }),
    },
  },
  data: () => ({
    visible: true,
  }),
};
</script>
